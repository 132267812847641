import React from "react"
// import { Link } from "gatsby"
import PropTypes from "prop-types"

import { CDNBASE } from "../utils/maps"
import st from "./css/banner.module.scss"
// import classNames from 'classnames'

const Banner = ({ v, i, h1 }) => {
  return (
    <section className={st.banner} style={{ backgroundColor: v.bg }} key={i}>
      <div className={st.bannerWrap}>
        <div className={st.text}>
          {i === 0 && (h1 ? <h1>为什么选择酷课服务预订系统？</h1> : <h2>为什么选择酷课服务预订系统？</h2>)}
          <h2>{v.title}</h2>
          <ul>
            {
              v.items.map((item, index) => (
                <li key={index}>{item}</li>
              ))
            }
          </ul>
        </div>
        <div className={st.pic} style={{ backgroundImage: `url(${CDNBASE}/home/advance/${v.img})` }}></div>
      </div>
    </section>
  )
}

Banner.propTypes = {
  v: PropTypes.object,
  i: PropTypes.number,
  h1: PropTypes.bool,
}

Banner.defaultProps = {
  v: {},
  i: 0,
  h1: false,
}

export default Banner;