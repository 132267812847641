import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import RoleFeature from "../components/roleFeature"
import CatNav from "../components/catnav"
import UsersSay from "../components/usersSay"
import Banner from "../components/banner"
import { HomeSvgs, CasesSvgs } from "../components/svgs"
import { CDNBASE } from "../utils/maps"
import advData from "../json/advance.json"
import st from "./css/home.module.scss"


const IndexPage = function () {
  let isMobile = false;
  if(typeof window !== "undefined"){
    isMobile = document.documentElement.clientWidth <= 768;
  }

  return (
    <Layout cur='/' stickyHeader >
      <SEO
        title="酷课快约"
        keywords="服务排期,会员预订服务,"
      />
      <HomeSvgs />
      <CasesSvgs />

      <div className={st.banner} style={{ backgroundImage: `url(${CDNBASE}/home/banner/1.png)` }}>
        <h1>为各类生活服务机构量身定制的服务预订管理系统</h1>
        <div className={st.link}><Link className='btn-pri btn-large' target="_blank" to="https://kukecloud.com/static/" >开始免费试用</Link></div>
        <div className={st.qrcode}>
          <img alt="酷课公众号" src={CDNBASE + '/src/qrcode/gzh_dark.gif'} />
          <h5>长按识别，开始免费试用</h5>
        </div>
        <div className={st.ill} style={{ backgroundImage: `url(${CDNBASE}/service/banner/1.1.jpg?005)` }}></div>
      </div>

      <RoleFeature isMobile={isMobile} />

      <div>
        {
          advData.slice(0, 4).map((v, i) => (
            <Banner key={i} v={v} i={i} />
          ))
        }
      </div>

    </Layout>
  )
}

export default IndexPage
